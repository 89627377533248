body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.custom-hover:hover {
  background-color: #8a9197; /* Darker shade for hover */
  border-color: #8a9197; /* Darker border for hover */
  color: #252525;
}

.custom-hover {
  background-color: transparent;
  color: rgba(78, 72, 72, 0.602);

  border: 1px solid rgb(172, 170, 170);
}

.margin-top-10 {
  margin-top: 10.2rem;
}

.no-bullets {
  list-style-type: none; /* Removes bullets */
  margin: 0;
  cursor: "move";
  background-color: #35475a33;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ccc;
}

.no-bullets:hover {
  background-color: #03223b33;
}

.custom-popover {
  margin-left: -9rem !important;
}

.switch {
  position: relative;
  display: inline-block;
  width:3rem;
  height:1.5rem;
  right: 3rem;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1rem;
  width: 1rem;
  left: 0.3rem;
  bottom: 0.3rem;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 1rem;
}

.slider.round:before {
  border-radius: 50%;
}
