@tailwind base;
@tailwind components;
@tailwind utilities;

.left-sidebar {
  z-index: 2 !important;
  margin-top: 0.8rem !important;
}

.left-sidebar-menu {
  font-family: "Nunito" !important;
  border-top: none !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  z-index: 1 !important;
  height: 100vh;
  background-color: white !important;
  width: 6rem !important;
 
}

@media (max-width: 991px) {
  .left-sidebar-menu {
    width: 5rem !important;
  }
}

/* For screens less than 768px (e.g., mobile devices in landscape mode) */
@media (max-width: 767px) {
  .left-sidebar-menu {
    width: 4rem !important; /* Reduce width for mobile landscape */
  }
}

/* For screens less than 576px (e.g., mobile devices in portrait mode) */
@media (max-width: 575px) {
  .left-sidebar-menu {
    width: 3rem !important; /* Further reduce width for mobile portrait */
  }
}

.left-sidebar-menu .nav-link.active {
  color: #53c4d0 !important;
  padding: 0 !important;
  text-align: -webkit-center !important;
}

.left-sidebar-menu .nav-link {
  padding: 0 !important;
  text-align: -webkit-center !important;
}

.text-tool-bar {
  min-height: 65px;
  background-color: white !important;
  z-index: 1;
}

.activeBackgroundButton {
  z-index: 2;
  position: fixed;
  margin-left: 85%;

}

.popover-container {
  position: relative;
}

.popover {
  position: absolute;
  top: 100%;
  left: 0;
  width: 10rem !important;
  height: 11.5rem !important;
  display: block;
  padding: 0.5rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.popover-down {
  transform: translateY(0.8px); /* Adjust the offset to suit your needs */
}

.clipto-border {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.text-panel-section {
  color: #01122b !important;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 20px;
}

.text-panel-heading {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
  line-height: 18px !important;
  letter-spacing: 0.06em !important;
  text-transform: uppercase !important;
  font-family: "Nunito" !important;
}

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
  color: #01122b !important;
}

.nav-tabs {
  position: relative;
}

.nav-item {
  flex-grow: 1;
}

.btn-group {
  height: 3rem;
  width: 3rem;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-right: 3px;
}

.btn-group-fontsize {
  width: 3rem !important;
  height: 3rem;
}

.fontsize-input {
  width: 3.8rem !important;
  height: 3rem;
}

.btn-group-fontfamily {
  width: 20rem;
  height: 3rem;
  margin-right: 2rem;
}
.image-panel {
  width: 100%;
}

.image-panel .nav-tabs::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0.1px;
  background-color: rgba(0, 0, 0, 0.2);
}

.container {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.image-panel .nav-link.active {
  color: #3898d1 !important;
  text-align: center;
  position: relative;
  background-color: transparent !important;
}

.image-panel .nav-link:hover {
  color: #225775 !important;
}

.image-panel .btn-search {
  font-family: "Nunito";
  font-size: 1.2rem;
  width: 50%;
  border-color: #3898d1;
  color: #3898d1;
  background-color: transparent;
}

.image-panel .btn-search:hover {
  color: #225775 !important;
}

.image-panel .nav-link {
  color: black;
  text-align: center;
  position: relative;
  border: none !important;
  margin-bottom: none !important;
  background-color: transparent !important;
}

.image-panel .nav-link::before {
  font-family: "Nunito" !important;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: transparent;
}

.image-panel .nav-link.active::before {
  font-family: "Nunito" !important;
  background-color: #3898d1 !important;
  height: 7%;
  width: 80%;
  left: 10%;
}

.internalpanel {
  width: 25rem;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  height: 93vh;
  overflow: hidden;
  margin-top: 10px;
  padding-left: 0;
  padding-right: 0;
  background-color: white;
}

.font-minus {
  margin-top: 2px !important;
}

.font-content {
  overflow: scroll !important;

  /* height: 45rem; */
}

#spinner-container{
  display: flex;
  justify-content: center ;
  margin: 3rem !important;
}

.addImageBtn {
  padding: 1rem;
}

#openFileButton {
  margin: 5rem;
}

#wrapper{
display: flex !important;
justify-content: center;
}

.alertText {
  height: 2rem;
  font-weight: 800;
  color: red;

}

.alertBackground {
  background-color: aliceblue;
  margin-left:1.5rem;
  margin-right:1.5rem;
  margin-bottom: 1rem;
  border: 1px solid grey;

}


.sneaker-container {

  flex-grow: 1;
  overflow: hidden;
  height: 100%;
  position: relative;
}

/* Drawer CSS  */
.content {
  padding: 20px;
}

.canvas-container {
  position: relative;
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
}



.container1 {
  display: flex;
  height: 100vh;
  width: 100%;
}

/* Center Area */
.center-area1 {
  /* background-color: #3fc1c9; */
  flex-grow: 2;
  min-width: 20rem; /* To prevent collapse */
  padding: 1rem;
}

/* Right Sidebar */
.right-sidebar1 {
  /* background-color: #fc5185; */
  height:100vh;
  background-color: white;
  position: relative;
  border-left:1px solid rgb(208, 208, 208);
  transition: 0.2s ease;
}
/* Resizing Handle for the Right Sidebar */

.resize-handle {
  content: "";
  position: absolute;
  left: -2rem; /* Placed at the left edge of the right sidebar */
  top: 50%;
  width: 2rem;
  height: 3rem;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: ew-resize;
  background-color: #ccc;
}

/* Drawer content */
.drawer-content {
  margin-top: 5rem;
  height: 100%;
  overflow-y: auto;
}



.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.overlay-content {
  width: auto;
  height: 90%;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(200px, 1fr)
  ); /* Creates a responsive grid */
  gap: 10px; /* Space between grid items */
}

.image-item {
  text-align: center;
  border: 1px solid #ddd;
  padding: 10px;
}

.image-item img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}
@media (max-width: 1600px) {
  .internalpanel {
    width: 15rem; /* Reduce width further for smaller screens */
  }
  .btn-group {
    height: 2rem;
    width: 2rem;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-right: 3px;
  }
  .font-minus {
    margin-top: 2px !important;
  }
  .btn-group-fontsize {
    width: 2rem !important;
    height: 2rem;
  }
  .fontsize-input {
    width: 4rem !important;
    height: 2rem;
  }

  .btn-group-fontfamily {
    width: 10rem;
    height: 2rem;
    margin-right: 3px;
  }

  .font-content {
    overflow: scroll !important;
    height: 85vh;

    /* height: 45rem; */
  }

  .sneaker-container {
    margin-top: 6rem;
  }
  #openFileButton {
    margin: 1rem;
  }
}

/* For screens less than 768px (e.g., mobile devices in landscape mode) */
@media (max-width: 1000px) {
  .elements {
    font-size: 0.6rem !important;
    height: 3rem !important;
  }
  .internalpanel {
    width: 12rem; /* Reduce width for mobile landscape */
  }
  .btn-group {
    height: 2rem;
    width: 2rem;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-right: 3px;
  }

  .btn-group-fontsize {
    width: 2rem !important;
    height: 2rem;
  }
  .fontsize-input {
    width: 3.6rem !important;
    height: 2rem !important;
  }

  .btn-group-fontfamily {
    width: 5rem;
    height: 2rem;
    margin-right: 3px;
  }
  .font-minus {
    margin-top: 1px !important;
  }

  .font-content {
    overflow: scroll !important;
    height: 80vh;

    /* height: 20rem !important; */
  }

  .sneaker-container {
    margin-top: 6rem;
  }

  #openFileButton {
    margin: 1rem;
  }

  .addImageBtn {
    padding: 0.5rem;
  }

  .color-panel {
    overflow: scroll;
    height: 22rem;
  }
}

/* For screens less than 576px (e.g., mobile devices in portrait mode) */
@media (max-width: 575px) {
  .text-tool-bar {
    margin-top: 6.5rem;
  }

  .artwork-toolbox {
    margin-top: 1rem !important;
  }

  .image-toolbox {
    margin-top: 1rem !important;
  }
  .column-menu {
    margin-top: 2rem;
  }

  .internalpanel {
    width: 10rem; /* Further reduce width for mobile portrait */
    margin-top: 6rem !important;
  }
  .btn-group {
    height: 1rem;
    width: 1rem;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-right: 2px;
  }

  .btn-group-fontsize {
    width: 2rem !important;
    height: 2rem;
  }
  .fontsize-input {
    width: 3rem !important;
    height: 1rem;
  }

  .btn-group-fontfamily {
    width: 5rem;
    height: 1rem;
    margin-right: 2px;
  }
  .font-minus {
    margin-top: 1rem !important;
  }

  .internalpanel .close-button {
    margin-left: 80% !important;
    z-index: 999 !important;
  }

  .left-sidebar-menu {
    width: 3.8rem !important;
  }

  .image-panel .btn-search {
    font-family: "Nunito";
    font-size: 15px;
    width: 80%;
    border-color: #3898d1;
    color: #3898d1;
    background-color: transparent;
  }

  #openFileButton {
    display: flex;
    justify-content: center;
    width: 9rem;
    margin-top: 2rem !important;
    margin-left: 0;
    padding: 0 !important;
    font-size: 15px;
  }

  .addImageBtn {
    padding: 0.1rem;
    font-size: 12px;
  }

  #myTabContent {
    padding: 0 !important;
  }
  .font-content {
    overflow: scroll !important;
    height: 60vh !important;

    /* height: 15rem !important; */
  }
  .sneaker-container {
    margin-top: 6rem;
  }

  .layer-font {
    font-size: 10px;
  }
  .logo {
    display: none;
  }

  .color-panel {
    overflow: scroll;
    height: 10rem;
  }
}

.searchpanel {
  overflow: scroll !important;
}

.collapse {
  display: hidden;
}

.nav-bar {
  z-index: 2 !important;
}

.internalpanel .close-button {
  margin-left: 90%;
  margin-top: 1rem;
  z-index: 999 !important;
}

.bookspecs-color2 {
  color: #53c4d0;
}
.bookspecs-color3 {
  color: #000000;
  font-weight: 400;
}

.bookspecs-color1 {
  color: #01122b;
  font-weight: 400;
}

.bookspecs-select {
  border-style: none;
  background-color: transparent;
}

.bookspecs-bg {
  background-color: #ebebeb;
  align-items: center;
  height: 4rem;
}

.bookspecs-height {
  height: 2rem;
}

.bookspecs-fs {
  font-size: 1.2rem;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
